import React from 'react';
import Map from './Map';

const Locations = () => {
  return (
    <div>
      <Map />
    </div>
  );
};

export default Locations;
