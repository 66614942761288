import { useQuery } from '@apollo/client';
import {
  Button,
  Divider,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { GET_EVENT_SCHEDULE, GET_PLAYER_DASHBOARD } from '../../queries';
import { orderBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
    overflow: 'auto'
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function PlayerDashboard({ token, eventId }) {
  const classes = useStyles();
  const [playerGames, setPlayerGames] = useState<any>();
  const history = useHistory();

  // if (!token) {
  //   history.push('/login');
  // }

  // const playerId = props.match.params.playerId;
  // const userId: any = localStorage.getItem('user-id');
  // const playerId = 'u_85FxH7DafQ';

  const playerId = localStorage.getItem('user-id');

  if (!playerId) {
    history.push('/login');
  }
  //u_85FxH7DafQ

  const { loading, error, data } = useQuery(GET_PLAYER_DASHBOARD, {
    variables: {
      id: playerId
    }
  });

  const schedule = useQuery(GET_EVENT_SCHEDULE, {
    variables: {
      id: eventId
    }
  });

  useEffect(() => {
    setPlayerGames(
      orderBy(
        schedule?.data?.event?.games?.filter(
          (row) =>
            data?.user?.teams?.map((team) => team.id).includes(row.home.id) ||
            data?.user?.teams?.map((team) => team.id).includes(row.away?.id)
        ),
        ['gameAt']
      )
    );
  }, [schedule, data]);

  const getNextGame = (games) => {
    if (!games) {
      return null;
    }
    // This shit doesn't work great because these APIs can never return
    // a consistent fucking timestamp
    const now = new Date();
    const nextGame = games?.find(
      (game) => !game.byeWeek && new Date(game.gameAt.substr(0, 10)).getTime() > now.getTime()
    );
    if (!nextGame) {
      return <span>TBD</span>;
    }
    return (
      <div>
        <a href={'/team/' + nextGame.home.id}>
          <i
            style={{
              color: nextGame.home?.color.hexCode,
              marginRight: 10,
              padding: 2,
              backgroundColor: nextGame.home?.color.hexCode === '#FFFFFF' ? 'black' : 'white'
            }}
            className="fas fa-tshirt"
          ></i>
          {nextGame.home?.name}
        </a>{' '}
        vs
        <a href={'/team/' + nextGame.away?.id}>
          <i
            style={{
              color: nextGame.away?.color.hexCode,
              marginRight: 10,
              padding: 2,
              backgroundColor: nextGame.away?.color.hexCode === '#FFFFFF' ? 'black' : 'white'
            }}
            className="fas fa-tshirt"
          ></i>
          {nextGame.away?.name}
        </a>
        - {nextGame.displayTime} on Field {nextGame.field?.name}
      </div>
    );
  };

  if (loading) {
    return <p>Loading ...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Paper style={{ margin: 20, padding: 20 }}>
      <Typography variant="h5">{data?.user?.fullName} </Typography>
      {/* <Link to="registration"> */}
      {/*  <Button type="submit" variant="contained" color="primary" className={classes.submit}> */}
      {/*    Register */}
      {/*  </Button> */}
      {/* </Link> */}
      <Divider style={{ margin: 10 }} />
      <Typography variant="h6">Next Game</Typography>
      <div>{getNextGame(playerGames)}</div>
      <Divider style={{ margin: 10 }} />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginRight: 10 }}>
          <Typography variant="h6">Teams</Typography>
          {data?.user?.teams?.map((team) => (
            <div>
              <a href={'/team/' + team.id}>
                <i
                  style={{
                    color: team.color?.hexCode,
                    marginRight: 10,
                    padding: 2,
                    backgroundColor: team.color?.hexCode === '#FFFFFF' ? 'black' : 'white'
                  }}
                  className="fas fa-tshirt"
                ></i>
                {team.name}
              </a>
              ({team.record})
            </div>
          ))}
        </div>
        <div>
          <Typography variant="h6">Schedule</Typography>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Home</TableCell>
                <TableCell>Away</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Field</TableCell>
                <TableCell>Result</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedule?.loading && (
                <TableRow>
                  <TableCell colSpan={4}>Loading...</TableCell>
                </TableRow>
              )}
              {playerGames?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <a href={'/team/' + row.home.id}>
                      <i
                        style={{
                          color: row.home?.color.hexCode,
                          marginRight: 10,
                          padding: 2,
                          backgroundColor: row.home?.color.hexCode === '#FFFFFF' ? 'black' : 'white'
                        }}
                        className="fas fa-tshirt"
                      ></i>
                      {row.home?.name}
                    </a>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.byeWeek ? (
                      <span>Bye Week (NO GAME)</span>
                    ) : (
                      <a href={'/team/' + row.away?.id}>
                        <i
                          style={{
                            color: row.away?.color.hexCode,
                            marginRight: 10,
                            padding: 2,
                            backgroundColor: row.away?.color.hexCode === '#FFFFFF' ? 'black' : 'white'
                          }}
                          className="fas fa-tshirt"
                        ></i>
                        {row.away?.name}
                      </a>
                    )}
                  </TableCell>
                  <TableCell>{row.byeWeek ? format(new Date(row.gameAt), 'MM/dd/yyyy') : row.displayTime}</TableCell>
                  <TableCell>{row.field?.name}</TableCell>
                  <TableCell>
                    {row.finalized ? (
                      <span>
                        {row.homeScore} - {row.awayScore}
                      </span>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </Paper>
  );
}
