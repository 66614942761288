import { gql } from '@apollo/client';

export const FIND_PERSON = gql`
  query user($id: ID!) {
    user(id: $id) {
      fullName
      phoneNumber
      id
      address {
        street
        city
      }
    }
  }
`;

export const GET_EVENT_DETAILS = gql`
  query event($id: ID!) {
    event(id: $id) {
      id
      details
    }
  }
`;

export const CREATE_PERSON = gql`
  mutation createPerson($input: createUserInput!) {
    createUser(input: $input) {
      firstName
      lastName
      id
      address {
        street
        city
      }
    }
  }
`;

export const PASSWORD_RESET = gql`
  mutation resetPassword($input: passwordResetInput!) {
    updatePassword(input: $input)
  }
`;

export const PASSWORD_RESET_REQUEST = gql`
  mutation sendpasswordreset($email: String!, $domain: String!) {
    generatePasswordToken(email: $email, domain: $domain)
  }
`;

export const ALL_PERSONS = gql`
  query {
    allUsers {
      fullName
      phoneNumber
      id
    }
  }
`;

export const ALL_USERS = gql`
  query {
    allUsers {
      fullName
      phoneNumber
      id
    }
  }
`;

// export const EDIT_NUMBER = gql`
//     mutation editNumber($name: String!, $phone: String!) {
//         editNumber(name: $name, phone: $phone)  {
//             name
//             phone
//             address {
//                 street
//                 city
//             }
//             id
//         }
//     }
// `

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    logIn(username: $username, password: $password) {
      token
      user {
        id
        firstName
        email
      }
    }
  }
`;

export const ALL_AUTHORS = gql`
  query {
    allAuthors {
      id
      name
      born
      bookCount
    }
  }
`;

export const ALL_BOOKS = gql`
  query allBooks($genre: String) {
    allBooks(genre: $genre) {
      id
      title
      published
      genres
      author {
        name
      }
    }
  }
`;

export const CURRENT_USER = gql`
  query {
    me {
      username
      favoriteGenre
      id
    }
  }
`;

export const ADD_BOOK = gql`
  mutation addBook($title: String!, $author: String!, $published: Int!, $genres: [String!]!) {
    addBook(title: $title, author: $author, published: $published, genres: $genres) {
      title
      author {
        name
      }
      published
      genres
    }
  }
`;

export const EDIT_AUTHOR = gql`
  mutation editAuthor($name: String!, $born: Int!) {
    editAuthor(name: $name, born: $born) {
      name
      born
    }
  }
`;

export const GET_TEAM = gql`
  query getTeam($id: ID!) {
    team(id: $id) {
      name
      color {
        hexCode
      }
      users {
        id
        firstName
        lastName
        fullName
        email
      }
      captain {
        id
        firstName
        lastName
        fullName
      }
      record
      games {
        id
        home {
          id
          name
          color {
            hexCode
          }
        }
        away {
          id
          name
          color {
            hexCode
          }
        }
        gameAt
        field {
          name
        }
        referee {
          fullName
        }
        homeScore
        finalized
        awayScore
        byeWeek
        displayTime
      }
    }
  }
`;

export const GET_EVENT = gql`
  query getEvent($id: ID!) {
    event(id: $id) {
      id
      name
      startDate
      endDate
      details
      ageRangeMin
      ageRangeMax
      maxTeams
      maxPlayers
      eventType
      activity {
        display
        iconSrc
        activity
      }
      genderRestriction
      userRegistrationTypes
      fees {
        id
        startDate
        endDate
        category
        type
        description
        amount
      }
      activeFees {
        id
        startDate
        endDate
        category
        type
        description
        amount
      }
      teams {
        id
        name
      }
      questionnaires {
        id
        name
        description
        propertyType
        policyText
        tooltip
        required
      }
      divisions {
        id
        name
        code
        description
        colors {
          color
          hexCode
          available
        }
      }
      uniforms {
        id
        size
        sizeCode
        material
        garment
        fit
        description
        cost
      }
      merch {
        description
        id
        code
        cost
        hasSizes
        sizes
        hasStyles
        styles
        hasColors
        colors
        imageUrl
        name
      }
      standings {
        division
        standings {
          team {
            id
            name
            color {
              hexCode
            }
            record
          }
          wins
          losses
          ties
          pointsScored
          pointsAllowed
          record
          position
        }
      }
    }
  }
`;

export const GET_EVENT_SCHEDULE = gql`
  query getEvent($id: ID!) {
    event(id: $id) {
      games {
        id
        home {
          id
          name
          color {
            hexCode
          }
        }
        away {
          id
          name
          color {
            hexCode
          }
        }
        gameAt
        field {
          name
        }
        referee {
          fullName
        }
        homeScore
        finalized
        awayScore
        byeWeek
        displayTime
        gameDate
      }
    }
  }
`;

export const GET_PLAYER_DASHBOARD = gql`
  query user($id: ID!) {
    user(id: $id) {
      fullName
      teams {
        id
        name
        color {
          hexCode
        }
        record
        active
      }
    }
  }
`;

export const REGISTER = gql`
  mutation register($RegistrationInput: RegistrationInput) {
    register(input: $RegistrationInput) {
      id
    }
  }
`;
