import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import { PASSWORD_RESET, PASSWORD_RESET_REQUEST } from '../../queries';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import { SportsBasketball } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import DisplayAlert from '../SignUp/DisplayAlert';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: 'url(https://www.leaguewarrior.com/247ksuf.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'blue'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

type passwordResetInput = {
  token: string;
  email: string;
  password: string;
};

export default function ResetPassword(props) {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const [password1Error, setPassword1Error] = useState(false);
  const [password2Error, setPassword2Error] = useState(false);

  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [success, setSuccess] = useState(false);

  const [resetPassword, result] = useMutation(PASSWORD_RESET, {
    onError: (error) => {
      console.log(error.message);
      setErrMsg(getHumanError(error.message));
      setOpen(true);
    }
  });

  function getHumanError(str) {
    return str.split(':')[1];
  }

  const history = useHistory();

  function validateResetForm() {
    return password1.length > 0 && password1 === password2;
  }

  function validatePassword() {
    setPassword1Error(false);
    if (password1.length < 8) {
      setPassword1Error(true);
    }
  }

  function validatePassword2() {
    setPassword2Error(false);
    if (password1 !== password2) {
      setPassword2Error(true);
    }
  }

  const token = props.match.params.token;

  useEffect(() => {
    if (result.error) {
      setErrMsg(result.error.graphQLErrors[0].message);
      setOpen(true);
      console.log('error in call');
    }
    if (result.data) {
      setSuccess(true);
    }
  }, [history, result.data]);

  const submit = () => {
    console.log(token);
    console.log(password1);
    console.log(username);
    const input: passwordResetInput = {
      token: token,
      email: username,
      password: password2
    };

    resetPassword({
      variables: { input: input }
    });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {open && <DisplayAlert message={errMsg} />}
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SportsBasketball />
          </Avatar>
          <Typography component="h1" variant="h5">
            Please reset your password
          </Typography>
          <form onSubmit={submit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="email"
              type="username"
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }} //whenever the text field change, you save the value in state
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password1"
              error={password1Error}
              onChange={(event) => {
                setPassword1(event.target.value);
              }} //whenever the text field change, you save the value in state
              onBlur={(event) => {
                validatePassword();
              }}
              helperText={'Password must be at least 8 characters'}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Confirm Password"
              type="password"
              id="password2"
              error={password2Error}
              helperText={password2Error ? 'Password do not match' : ''}
              onChange={(event) => {
                setPassword2(event.target.value);
              }} //whenever the text field change, you save the value in state
              onBlur={(event) => {
                validatePassword2();
              }}
            />
            <Button
              onClick={submit}
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!validateResetForm()}
            >
              Reset Password
            </Button>
          </form>
          {success && (
            <Typography variant="body1">
              Your password has been successfully reset <br />
              Please <Link href="/login">login to your account</Link>
            </Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
