import { makeStyles, Paper } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  formControl: {
    minWidth: 300,
    marginBottom: 10
  },
  cardElement: {
    marginTop: 20,
    marginBottom: 20
  },
  bdlPaper: {
    padding: 10,
    marginTop: 10,
    marginBottom: 10,
    '& div:first-child': {
      margin: 0
    }
  }
}));

export interface PaymentInputProps {
  onChange: (stripe: Stripe, element: StripeCardElement) => void;
}

export default function PaymentInput(input: PaymentInputProps) {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const { onChange } = input;

  const stripeChange = (event: StripeCardElementChangeEvent) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    if (event.error) {
      return;
    }
    onChange(stripe, elements.getElement(CardElement) as any);
  };

  return (
    <div className={classes.root}>
      <span>Please enter payment details</span>
      <Paper className={classes.bdlPaper}>
        <CardElement className={classes.cardElement} onChange={stripeChange} />
      </Paper>
    </div>
  );
}
