import React from 'react';
import './SponsorsList.css';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        padding: 6
    },
    imageList: {
        width: 500,
        height: 450,
        alignItems: 'center'
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)'
    }

}));
const SponsorsList = () => {
    const classes = useStyles();
    const sponsorLinks = [
        { url: 'https://www.ironvalleyrealestateofcentralpa.com/agents/1169614/Jason+McMullen', image: 'https://www.247kickball.com/assets/sponsormcmullen.png' },
        { url: 'https://www.kostmortgage.com', image: 'https://www.247kickball.com/assets/sponsorkost.png' },
        { url: 'https://www.gratefulgoatbrewing.com', image: 'https://www.247kickball.com/assets/sponsorgoat.png' },
        { url: 'https://www.aroogas.com', image: 'https://www.247kickball.com/assets/sponsoraroogas.png' },
        { url: 'https://www.cartridgeworld.com', image: 'https://www.247kickball.com/assets/sponsorcartridge.png' },
        { url: 'https://www.wilsbach.com', image: 'https://www.247kickball.com/assets/sponsorarizona.png' },
        { url: 'https://www.wilsbach.com', image: 'https://www.247kickball.com/assets/sponsormichelob.png' },
        { url: 'https://www.harrisburgacademy.org', image: 'https://www.247kickball.com/assets/sponsoracademy.png' }

    ];

    return (
        <Grid xs={12} container direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <div  className={classes.root}>
            <Typography variant="h5">Please Support Our League Sponsors</Typography>
            <Grid item xs={12} className={classes.imageList}>
            <div className="sponsers-list">
                {sponsorLinks.map((sponsor, index) => (
                    <a key={index} href={sponsor.url} target="_blank" rel="noopener noreferrer" className="sponsor-link">
                        <img src={sponsor.image} alt={`Sponsor ${index + 1}`} className="sponsor-image" />
                    </a>
                ))}
            </div>
            </Grid>
        </div>
        </Grid>
    );
};

export default SponsorsList;
