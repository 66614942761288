import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  Typography,
  FormHelperText,
  MenuItem
} from '@material-ui/core';
import { Merchandise } from '../../api/models/Merchandise';
import { MerchandiseOrder } from '../../api/models/MerchandiseOrder';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  formControl: {
    minWidth: 300,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  bdlPaper: {
    padding: theme.spacing(2, 4, 3)
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  }
}));

export interface MerchProps {
  item: Merchandise;
  index: number;
  cart: MerchandiseOrder[];
  setCart: Dispatch<SetStateAction<MerchandiseOrder[]>>;
}

export default function Merch({ item, index, cart, setCart }: MerchProps) {
  const classes = useStyles();

  const [quantity, setQuantity] = useState(0);
  const [style, setStyle] = useState('');
  const [color, setColor] = useState('');
  const [size, setSize] = useState('');
  const [colorRequired, setColorRequired] = useState(false);
  const [sizeRequired, setSizeRequired] = useState(false);
  const [styleRequired, setStyleRequired] = useState(false);
  const [quantityRequired, setQuantityRequired] = useState(false);
  const [ordered, setOrdered] = useState(false);

  useEffect(() => {
    // Check to see if this merch item is already in the cart.  It would be there if user went back from payment step
    const existingOrder = cart.find((order) => order.itemId === item.id);
    if (existingOrder) {
      setQuantity(existingOrder.quantity || 0);
      setStyle(existingOrder.style || '');
      setColor(existingOrder.color || '');
      setSize(existingOrder.size || '');
      setOrdered(true);
    }
  }, []);

  const handleAdd = (item) => {
    if (quantity < 1) {
      setQuantityRequired(true);
      return;
    } else {
      setQuantityRequired(false);
    }

    if (item.hasStyles) {
      if (style === '') {
        console.log('empty style');
        setStyleRequired(true);
        return;
      }
    }

    if (item.hasColors) {
      if (color === '') {
        console.log('empty color');
        setColorRequired(true);
        return;
      }
    }

    if (item.hasSizes) {
      if (size === '') {
        console.log('empty size');
        setSizeRequired(true);
        return;
      }
    }

    const order: MerchandiseOrder = {
      quantity,
      itemId: item.id,
      style,
      color,
      size,
      total: quantity * item.cost
    };

    setCart((cart) => [...cart, order]);
    setColorRequired(false);
    setSizeRequired(false);
    setStyleRequired(false);
    setOrdered(true);
  };

  const handleRemove = () => {
    setCart(cart.filter((order) => order.itemId !== item.id));
    setOrdered(false);
  };

  return (
    <FormControl className={classes.formControl}>
      <Paper variant="outlined" className={classes.bdlPaper}>
        <Typography variant="h6" gutterBottom>
          {item.name}
        </Typography>
        <div>
          <img src={item.imageUrl} className={classes.img} />
        </div>
        <br />
        <Typography variant="caption">Description</Typography>
        <Typography variant="body2">{item.description}</Typography>
        <br />
        <br />
        <Typography variant="body2">${item.cost}</Typography>
        <br />
        <br />
        <div>
          <FormControl className={classes.formControl} error={quantityRequired}>
            <InputLabel htmlFor="age-native-simple">Quantity</InputLabel>
            <Input
              disabled={ordered}
              value={quantity}
              type="number"
              onChange={(e) => setQuantity(e.target.value as unknown as SetStateAction<number>)}
            />
            <FormHelperText>{quantityRequired ? 'Quantity Required' : ''}</FormHelperText>
          </FormControl>
        </div>
        {item.hasStyles ? (
          <FormControl className={classes.formControl} error={styleRequired}>
            <InputLabel id="style-label">Style</InputLabel>
            <Select
              labelId="style-label"
              id="style"
              value={style}
              disabled={ordered}
              onChange={(e) => setStyle(e.target.value as unknown as SetStateAction<string>)}
            >
              {item.styles?.map((style, index) => (
                <MenuItem key={index} value={style as unknown as string[]}>
                  {style}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{styleRequired ? 'Style Required' : ''}</FormHelperText>
          </FormControl>
        ) : null}
        {item.hasColors ? (
          <div>
            <FormControl className={classes.formControl} error={colorRequired}>
              <InputLabel id="color-label">Color</InputLabel>
              <Select
                labelId="color-label"
                id="color"
                value={color}
                disabled={ordered}
                onChange={(e) => setColor(e.target.value as unknown as SetStateAction<string>)}
              >
                {item.colors?.map((color, index) => (
                  <MenuItem key={index} value={color}>
                    {color}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{colorRequired ? 'Color Required' : ''}</FormHelperText>
            </FormControl>
          </div>
        ) : null}
        {item.hasSizes ? (
          <div>
            <FormControl className={classes.formControl} error={sizeRequired}>
              <InputLabel id="size-label">Size</InputLabel>
              <Select
                labelId="size-label"
                id="size"
                value={size}
                disabled={ordered}
                onChange={(e) => setSize(e.target.value as unknown as SetStateAction<string>)}
              >
                {item.sizes?.map((size, index) => (
                  <MenuItem key={index} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{sizeRequired ? 'Size Required' : ''}</FormHelperText>
            </FormControl>
          </div>
        ) : null}
        <Button
          disabled={ordered}
          variant="contained"
          color="primary"
          onClick={() => handleAdd(item)}
          className={classes.button}
        >
          Add
        </Button>
        <Button
          disabled={!ordered}
          variant="contained"
          color="secondary"
          onClick={() => handleRemove()}
          className={classes.button}
        >
          Remove
        </Button>
      </Paper>
    </FormControl>
  );
}
