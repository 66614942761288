import { FormControl, InputLabel, makeStyles, MenuItem, Select, FormHelperText } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import { Team } from '../../api/models/Team';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  formControl: {
    minWidth: 300,
    marginBottom: 10
  }
}));

type PlayerInputsProps = {
  team?: Team; // User selected team
  teams: Team[]; // Teams from API
  onPlayerInputs(inputs: Team): void; // Function for parent
  error: string;
};

export default function PlayerInputs(input: PlayerInputsProps) {
  const classes = useStyles();

  const { teams, onPlayerInputs, error } = input; // Properties
  const [team, setTeam] = useState<Team | undefined>(input.team); // State

  const onChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTeam(event.target.value as Team); // For this local component
    onPlayerInputs(event.target.value as Team); // For parent component
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl} error={!!error}>
        <InputLabel id="team-label">Team</InputLabel>
        <Select labelId="team-label" id="team-type" value={team} onChange={onChange}>
          {teams.map((item) => (
            <MenuItem value={item as unknown as string[]}>{item.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </div>
  );
}
