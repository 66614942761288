import { FormControl, FormHelperText, Input, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { Color } from '../../api/models/Color';
import { Division } from '../../api/models/Division';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  formControl: {
    minWidth: 300,
    marginBottom: 10
  }
}));

export interface TeampInputsProps {
  teamName: string;
  setTeamName: Dispatch<SetStateAction<string>>;
  division: Division;
  setDivision: Dispatch<SetStateAction<Division>>;
  color: Color;
  setColor: Dispatch<SetStateAction<Color>>;
  divisions: Division[]; // Divisions from API
  // colors: Color[]; // Colors from API
  errors: any;
}

export default function TeamInputs({
  teamName,
  setTeamName,
  division,
  setDivision,
  color,
  setColor,
  divisions,
  // colors,
  errors
}: TeampInputsProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl} error={!!errors?.teamName}>
        <InputLabel id="team-name-label">Team Name</InputLabel>
        <Input
          id="team-name-label"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value as unknown as SetStateAction<string>)}
        />
        <FormHelperText>{errors?.teamName}</FormHelperText>
      </FormControl>
      <br></br>
      <FormControl className={classes.formControl} error={!!errors?.division}>
        <InputLabel id="division-label">Division</InputLabel>
        <Select
          labelId="division-label"
          id="division"
          value={division}
          onChange={(e) => setDivision(e.target.value as unknown as SetStateAction<Division>)}
          renderValue={(item) => (item as any).name}
        >
          {divisions.map((item) => (
            <MenuItem key={item.id} value={item as any}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors?.division}</FormHelperText>
      </FormControl>
      <br></br>
      {division && (
        <FormControl className={classes.formControl} error={!!errors?.color}>
          <InputLabel id="color-label">Color</InputLabel>
          <Select
            labelId="color-label"
            id="color"
            value={color}
            onChange={(e) => setColor(e.target.value as unknown as SetStateAction<Color>)}
          >
            {division.colors.map((item) => (
              <MenuItem key={item.hexCode} value={item as any} disabled={!item.available}>
                <i style={{ color: item.hexCode, marginRight: 10 }} className="fas fa-tshirt"></i>
                {item.color}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors?.color}</FormHelperText>
        </FormControl>
      )}
    </div>
  );
}
