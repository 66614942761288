import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import { SportsBasketball } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import { LOGIN, PASSWORD_RESET_REQUEST } from '../../queries';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: 'url(https://www.leaguewarrior.com/247ksuf.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'blue'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function ForgotPassword(props) {
  const classes = useStyles();
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [clicked, setClick] = useState(false);

  const [passwordReset, result] = useMutation(PASSWORD_RESET_REQUEST, {
    onError: (error) => {
      console.log(error.message);
    }
  });

  const submit = (event) => {
    event.preventDefault();
    localStorage.clear();
    passwordReset({
      variables: {
        email: username,
        domain: 'https://www.247kickball.com'
      }
    });

    console.log('do something here');
    setClick(true);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SportsBasketball />
          </Avatar>
          <Typography component="h1" variant="h5">
            Please enter e-mail address
          </Typography>
          <form onSubmit={submit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="email"
              type="username"
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }} //whenever the text field change, you save the value in state
              autoFocus
            />
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={clicked}>
              Send Reset Email
            </Button>
            {clicked && (
              <Typography variant="body1">
                Please check your email for reset instructions. <br />
                If you do not receive an email, please reach out to your league administrator.
              </Typography>
            )}
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
