import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Color } from '../../api/models/Color';
import { Division } from '../../api/models/Division';
import { Event } from '../../api/models/Event';
import { MerchandiseOrder } from '../../api/models/MerchandiseOrder';
import { RegistrationInput } from '../../api/models/RegistrationInput';
import { Team } from '../../api/models/Team';
import { Uniform } from '../../api/models/Uniform';
import { GET_EVENT, REGISTER } from '../../queries';
import Merch from '../Merch/Merch';
import DisplayAlert from '../SignUp/DisplayAlert';
import FreeAgentInputs from './FreeAgentInputs';
import PaymentInput from './PaymentInput';
import PlayerInputs from './PlayerInputs';
import QuestionnaireInput, { QuestionnaireRegInput } from './QuestionnaireInput';
import TeamInputs from './TeamInputs';
import { useHistory } from 'react-router-dom';
import { User } from '../../api/models/User';

//const stripePromise = loadStripe('pk_test_6zSwdIsGtbgpqJ6xlxGBifrG');
const stripePromise = loadStripe('pk_live_JNfH0R4wgmx6ewj7nrSil7LN');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  formControl: {
    minWidth: 300,
    marginBottom: 10
  }
}));

function getSteps() {
  return ['Registration', 'Waiver', 'Swag', 'Payment'];
}

export interface RegistrationInputs {
  leagueId: string;
  eventId: string;
  token: string | null;
  user: {
    id: string;
    email: string;
  };
  notifyWith: (message: string, type?: string, timer?: number) => void;
}

export default function Registration({ eventId, user, notifyWith, token }: RegistrationInputs) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [eventFee, setEventFee] = useState(0);
  const [registrationType, setRegistrationType] = useState('');
  const [teamName, setTeamName] = useState('');
  const [color, setColor] = useState<Color>('' as unknown as Color);
  const [division, setDivision] = useState<Division>('' as unknown as Division);
  const [uniform, setUniform] = useState<Uniform>('' as unknown as Uniform);
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [team, setTeam] = useState<Team>();
  const [questionnaireResultInput, setQuestionnaireResultInput] = useState<QuestionnaireRegInput[]>([]);
  const [questionnaireSubmitted, setQuestionnaireSubmitted] = useState(false);
  const steps = getSteps();
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [cardElement, setCardElement] = useState<StripeCardElement>(null as any);
  const [disable, setDisable] = useState(false);
  const [register, registerResponse] = useMutation(REGISTER);
  const [cart, setCart] = useState<MerchandiseOrder[]>([]);
  const [total, setTotal] = useState<number>(0);

  const [errors, setErrors] = useState<any>({});

  const history = useHistory();

  // TODO: I don't know if this section works, i added .error on registerResponse
  // Handle REGISTER mutation response
  useEffect(() => {
    const { error } = registerResponse;
    if (error) {
      setOpen(true);
      setErrMsg(`Error registering: ${error.message.split(':')[1]}`);
    } else {
      setOpen(false);
    }
  }, [registerResponse.error]);

  useEffect(() => {
    let cartTotal = eventFee;
    cart.forEach((order) => (cartTotal += order.total));
    setTotal(cartTotal);
  }, [cart]);

  const validateRegistrationType = (step1Errors) => {
    if (!registrationType) {
      step1Errors = { ...step1Errors, registrationType: 'Registration Type Is Required' };
    } else {
      delete step1Errors.registrationType;
    }
    return step1Errors;
  };
  useEffect(() => {
    setErrors({ ...validateRegistrationType(errors?.step1 || {}) });
  }, [registrationType]);

  const validateUniform = (step1Errors) => {
    if (!uniform) {
      step1Errors = { ...step1Errors, uniform: 'Tee Shirt Size Required' };
    } else {
      delete step1Errors.uniform;
    }
    return step1Errors;
  };
  useEffect(() => {
    setErrors({ ...validateUniform(errors?.step1 || {}) });
  }, [uniform]);

  const validateDivisions = (step1Errors) => {
    if (!divisions?.length) {
      step1Errors = { ...step1Errors, divisions: 'Division(s) Required' };
    } else {
      delete step1Errors.divisions;
    }
    return step1Errors;
  };
  useEffect(() => {
    setErrors({ ...validateDivisions(errors?.step1 || {}) });
  }, [divisions]);

  const validateTeamName = (step1Errors) => {
    if (!teamName) {
      step1Errors = { ...step1Errors, teamName: 'Team Name Required' };
    } else {
      delete step1Errors.teamName;
    }
    return step1Errors;
  };
  useEffect(() => {
    setErrors({ ...validateTeamName(errors?.step1 || {}) });
  }, [teamName]);

  const validateTeam = (step1Errors) => {
    if (!team) {
      step1Errors = { ...step1Errors, team: 'Team Required' };
    } else {
      delete step1Errors.team;
    }
    return step1Errors;
  };
  useEffect(() => {
    setErrors({ ...validateTeamName(errors?.step1 || {}) });
  }, [team]);

  const validateDivision = (step1Errors) => {
    if (!division) {
      step1Errors = { ...step1Errors, division: 'Color Required' };
    } else {
      delete step1Errors.division;
    }
    return step1Errors;
  };
  useEffect(() => {
    setErrors({ ...validateDivision(errors?.step1 || {}) });
  }, [division]);

  const validateColor = (step1Errors) => {
    if (!color) {
      step1Errors = { ...step1Errors, color: 'Color Required' };
    } else {
      delete step1Errors.color;
    }
    return step1Errors;
  };
  useEffect(() => {
    setErrors({ ...validateColor(errors?.step1 || {}) });
  }, [color]);

  const isStep1Valid = (): boolean => {
    let step1Errors = errors?.step1 || {};

    // Registration Type required
    step1Errors = validateRegistrationType(step1Errors);

    // T-Shirt required
    step1Errors = validateUniform(step1Errors);

    // If Registration Type = FREE_AGENT
    // Division required
    if (registrationType === 'FREE_AGENT') {
      step1Errors = validateDivisions(step1Errors);
    }

    // If Registration Type = TEAM
    // Team name required
    if (registrationType === 'TEAM') {
      step1Errors = validateTeamName(step1Errors);
      step1Errors = validateDivision(step1Errors);
      step1Errors = validateColor(step1Errors);
    }

    // If Registration Type = PLAYER
    // Team required
    if (registrationType === 'PLAYER') {
      step1Errors = validateTeam(step1Errors);
    }

    setErrors({ step1: step1Errors });

    return !Object.keys(step1Errors).length;
  };

  // console.log(division);

  const handleNext = () => {
    console.log(`handle next, current step ${activeStep}`);

    if (activeStep === 0) {
      const valid = isStep1Valid();
      if (!valid) {
        return;
      }
    }

    if (activeStep === 1) {
      setQuestionnaireSubmitted(true);
      const numOfQsRequired = event.questionnaires.filter((q) => q.required).length;
      if (numOfQsRequired > questionnaireResultInput.length) {
        // This will happen on first step submission if no responses to questionnairs have been given
        return;
      }
      const inputsRequired = questionnaireResultInput.filter((item) => item.required && !item.result);
      if (inputsRequired?.length) {
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // todo: uncomment and verify redirect to login to get token
  if (!token) {
    history.push('/login');
  }

  const userId: any = localStorage.getItem('user-id');

  const { loading, error, data } = useQuery(GET_EVENT, {
    variables: {
      id: eventId
    }
  });

  if (loading) {
    return <p>Loading ...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const event: Event = data.event;

  // console.log('Event', event);

  const onQuestionnaireInput = (input: QuestionnaireRegInput) => {
    const index = questionnaireResultInput.findIndex((item) => item.questionnaireId === input.questionnaireId);
    if (index === -1) {
      questionnaireResultInput.push(input);
    } else {
      questionnaireResultInput[index] = input;
    }
    setQuestionnaireResultInput(questionnaireResultInput);
  };

  const getQuestionnaireInput = (questionnaireId: string): QuestionnaireRegInput => {
    return (
      questionnaireResultInput.find((item: QuestionnaireRegInput) => item.questionnaireId === questionnaireId) ||
      ({} as unknown as QuestionnaireRegInput)
    );
  };

  const registrationTypeChange = (domEvent: React.ChangeEvent<{ value: unknown }>) => {
    const regType = domEvent.target.value as string;
    updateActiveFee(regType);
    setRegistrationType(regType);
  };

  const updateActiveFee = (regType: string) => {
    const feeAmount = event.activeFees.find((fee) => fee.type.includes(regType))?.amount;
    if (isNaN(feeAmount as unknown as number)) {
      notifyWith(`No active fee found for registration type ${regType}`);
    }
    setEventFee(feeAmount || 0);
    setTotal(feeAmount || 0.0);
  };

  const handleFreeAgentInputs = (inputs: Division[]): void => {
    setDivisions(inputs);
  };

  const handlePlayerInputs = (inputs: Team): void => {
    setTeam(inputs);
  };

  const onUniformChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUniform(event.target.value as Uniform);
  };

  const paymentInputChanged = (stripe: Stripe | null, element: StripeCardElement) => {
    console.log('element', element);
    setStripe(stripe);
    setCardElement(element);
  };

  const submit = async () => {
    setOpen(false);
    setErrMsg('');
    setDisable(true);
    if (stripe) {
      const { error, token } = await stripe.createToken(cardElement);
      if (error) {
        console.error('Stripe Error', error);
        setOpen(true);
        setErrMsg(error.message as string);
        setDisable(false);
        console.log('should have set open', error.message);
        return;
      }
      if (!token) {
        console.error('Stripe response missing token');
        setOpen(true);
        setErrMsg('Sorry, we were unable to process your payment.  Please try again later.');
        setDisable(false);
        return;
      }
      const input: RegistrationInput = {
        cardToken: token.id,
        amount: total,
        initiatingUserId: userId,
        registrationDetails: [
          {
            eventId,
            userId: userId,
            uniformId: uniform.id,
            userRegistrationType: registrationType,
            questionnaireResults: questionnaireResultInput.map((item) => {
              delete item.required;
              return item;
            }),
            ...(registrationType === 'TEAM' && {
              teamDetailsInput: {
                // colorId: color.id!, somethign is fucked up with this???
                colorId: color.color,
                divisionId: division.id as string,
                name: teamName
              }
            }),
            ...(registrationType === 'PLAYER' && {
              playerDetailsInput: {
                teamId: team?.id as string
              }
            }),
            ...(registrationType === 'FREE_AGENT' && {
              freeAgentDetailsInput: {
                divisions: divisions.map((division) => division.name).join(',')
              }
            })
          }
        ],
        merchDetails: cart.map(({ itemId, quantity, color, size, style }) => {
          return {
            merchId: itemId,
            quantity,
            size: size || undefined,
            style: style || undefined,
            color: color || undefined
          };
        })
      };
      console.log('Sending Registration', input);
      try {
        await register({
          variables: {
            RegistrationInput: input
          }
        });
        console.log('Registration Sent');
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error('Graphql Error', error);
      }
    } else {
      setOpen(true);
      setErrMsg('Payment cannot be processed');
    }
    setDisable(false);
  };

  // console.log(event);

  const actions = (next: boolean, back: boolean, finish: boolean) => {
    return (
      <div>
        {back ? (
          <Button onClick={handleBack} className={classes.button}>
            Back
          </Button>
        ) : null}
        {next ? (
          <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
            Next
          </Button>
        ) : null}
        {finish ? (
          <Button variant="contained" color="primary" onClick={submit} disabled={disable} className={classes.button}>
            Pay
          </Button>
        ) : null}
      </div>
    );
  };

  return (
    <Elements stripe={stripePromise}>
      <div className={classes.root}>
        {open && <DisplayAlert message={errMsg} />}
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key={steps[0]}>
            <StepLabel>{steps[0]}</StepLabel>
            <StepContent>
              <div>{event?.name}</div>
              <br></br>
              <FormControl className={classes.formControl} error={!!errors?.step1?.registrationType}>
                <InputLabel id="registration-type-label">Registration Type</InputLabel>
                <Select
                  labelId="registration-type-label"
                  id="registration-type"
                  value={registrationType}
                  onChange={registrationTypeChange}
                  required={true}
                >
                  {event.userRegistrationTypes.includes('TEAM') ? (
                    <MenuItem disabled={event?.teams?.length >= event?.maxTeams} value={'TEAM'}>
                      Team
                    </MenuItem>
                  ) : null}
                  {event.userRegistrationTypes.includes('PLAYER') ? (
                    <MenuItem disabled={event?.teams?.length === 0} value={'PLAYER'}>
                      Player
                    </MenuItem>
                  ) : null}
                  {event.userRegistrationTypes.includes('FREE_AGENT') ? (
                    <MenuItem value={'FREE_AGENT'}>Free Agent</MenuItem>
                  ) : null}
                </Select>
                <FormHelperText>{errors?.step1?.registrationType}</FormHelperText>
              </FormControl>
              {registrationType === 'TEAM' ? (
                <TeamInputs
                  teamName={teamName}
                  setTeamName={setTeamName}
                  color={color}
                  setColor={setColor}
                  division={division}
                  setDivision={setDivision}
                  divisions={event?.divisions}
                  // colors={event?.colors}
                  errors={errors?.step1}
                ></TeamInputs>
              ) : null}
              {registrationType === 'PLAYER' ? (
                <PlayerInputs
                  team={team}
                  teams={event?.teams}
                  onPlayerInputs={handlePlayerInputs}
                  error={errors?.step1?.team}
                ></PlayerInputs>
              ) : null}
              {registrationType === 'FREE_AGENT' ? (
                <FreeAgentInputs
                  division={divisions}
                  divisions={event?.divisions}
                  onFreeAgentInputs={handleFreeAgentInputs}
                ></FreeAgentInputs>
              ) : null}
              <br></br>
              <FormControl className={classes.formControl} error={!!errors?.step1?.uniform}>
                <InputLabel id="tshirt-label">Tee Shirt (Size, Cut)</InputLabel>
                <Select
                  labelId="tshirt-label"
                  id="tshirt"
                  value={uniform}
                  onChange={onUniformChange}
                  renderValue={(item) => (item as any).description}
                  required={true}
                >
                  {event?.uniforms.map((item) => (
                    <MenuItem key={item.id} value={(item as any) || ''}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.step1?.uniform}</FormHelperText>
              </FormControl>
              {actions(true, false, false)}
            </StepContent>
          </Step>
          <Step key={steps[1]}>
            <StepLabel>{steps[1]}</StepLabel>
            <StepContent>
              {event.questionnaires.map((item) => (
                <QuestionnaireInput
                  key={item.id}
                  questionnaire={item}
                  questionnaireResultInput={getQuestionnaireInput(item.id)}
                  onQuestionnaireInput={onQuestionnaireInput}
                  formSubmitted={questionnaireSubmitted}
                ></QuestionnaireInput>
              ))}
              {actions(true, true, false)}
            </StepContent>
          </Step>
          <Step key={steps[2]}>
            <StepLabel>{steps[2]}</StepLabel>
            <StepContent>
              {event.merch.map((item, index) => (
                <Merch item={item} index={index} cart={cart} setCart={setCart} key={item.id} />
              ))}
              {actions(true, true, false)}
            </StepContent>
          </Step>
          <Step key={steps[3]}>
            <StepLabel>{steps[3]}</StepLabel>
            <StepContent>
              <PaymentInput onChange={paymentInputChanged}></PaymentInput>
              {registerResponse?.loading && <p>Completing...</p>}
              {actions(false, true, true)}
            </StepContent>
          </Step>
        </Stepper>
        <Paper square elevation={0} className={classes.resetContainer}>
          {total > 0 && (
            <Typography style={{ fontSize: '24px' }}>
              Total: ${total}
              {activeStep === steps.length && <span style={{ marginLeft: 30, color: 'green' }}>Paid &#10004;</span>}
            </Typography>
          )}
        </Paper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>
              Thank you for registering for <b>{event?.name}</b>. You should be receiving an email from{' '}
              <i>mailer@leaguewarrior.com</i> sent to <i>{user.email}</i> containing your receipt. Thanks!
            </Typography>
          </Paper>
        )}
      </div>
    </Elements>
  );
}
