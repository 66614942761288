import React from 'react';

const User = ({ user }) => {
  return (
    <tr>
      <td>{user.id}</td>
      <td>{user.fullName}</td>
      <td>{user.phoneNumber}</td>
    </tr>
  );
};

export default User;
