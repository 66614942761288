import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ALL_USERS } from '../../queries';
import User from './User';

const Users = ({ token }) => {
  const [loadingResult, result] = useLazyQuery(ALL_USERS, {});
  const history = useHistory();
  useEffect(() => {
    loadingResult();
  }, [loadingResult]);

  if (!result.called || result.loading) {
    return <div>loading...</div>;
  }

  if (!token) {
    history.push('/login');
  }

  const users = result.data.allUsers;
  // let genres = Array.prototype.concat.apply([], books.map(b => b.genres))
  // genres = [...new Set(genres)]

  // const onClick = (value) => {
  //     setFilterGenre(value)
  //     loadingResult()
  // }

  return (
    <div>
      <h2>Users</h2>
      <table>
        <tbody>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Phone Number</th>
          </tr>
          {users.map((user) => (
            <User key={user.id} user={user} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
