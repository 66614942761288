import { PinDrop } from '@material-ui/icons';
import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AnyReactComponent = ({ lat, lng, text }) => (
  <div>
    <PinDrop />
    {text}
  </div>
);

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 40.25746297053133,
      lng: -76.89127994231508
    },
    zoom: 15
  };
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '50%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyC0QBSZH41u-Y0IgDOcbB2xJ38fNKno7Cg' }}
          defaultCenter={Map.defaultProps.center}
          defaultZoom={Map.defaultProps.zoom}
        >
          <AnyReactComponent lat={40.25746297053133} lng={-76.89127994231508} text="247Kickball" /> 
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
