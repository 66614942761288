import { useQuery } from '@apollo/client';
import {
  Container,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { GET_EVENT_SCHEDULE } from '../../queries';
import ScheduleCard from './ScheduleCard';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  table: {
    minWidth: 650
  }
}));

export default function Schedule({ eventId }) {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_EVENT_SCHEDULE, {
    variables: {
      id: eventId
    }
  });

  const schedule = data?.event?.games;

  if (loading) {
    return <p>Loading ...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  //grabbing unique dates to make unique arrays of weeks
  const sched: any[] = [];
  const unique = schedule.map((item) => item.gameDate).filter((value, index, self) => self.indexOf(value) === index);

  unique.forEach(function (item, index) {
    const newArray = schedule.filter(function (el) {
      return el.gameDate === item;
    });
    sched.push(newArray);
  });

  return (
    //<Roster users={data?.team?.users} teamName={data?.team?.name} captain={data?.team?.captain} />
    <div>
      {sched.map((game) => {
        return <ScheduleCard games={game} />;
      })}
    </div>
    // <Container style={{ alignItems: 'center' }}>
    //   <TableContainer component={Paper} style={{ marginBottom: 20 }}>
    //     <Typography variant="h5" style={{ margin: 15 }}>
    //       Regular Season Schedule
    //     </Typography>
    //     <Table className={classes.table} aria-label="simple table">
    //       <TableHead>
    //         <TableRow>
    //           <TableCell>Home</TableCell>
    //           <TableCell>Away</TableCell>
    //           <TableCell>Time</TableCell>
    //           <TableCell>Field</TableCell>
    //           <TableCell>Ref</TableCell>
    //           <TableCell>Results</TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {schedule?.map((row) => (
    //           <TableRow key={row.id}>
    //             <TableCell component="th" scope="row">
    //               <a href={'/team/' + row.home.id}>
    //                 <i
    //                   style={{
    //                     color: row.home?.color.hexCode,
    //                     marginRight: 10,
    //                     padding: 2,
    //                     backgroundColor: row.home?.color.hexCode === '#FFFFFF' ? 'black' : 'white'
    //                   }}
    //                   className="fas fa-tshirt"
    //                 ></i>
    //                 {row.home?.name}
    //               </a>
    //             </TableCell>
    //             <TableCell component="th" scope="row">
    //               {row.byeWeek ? (
    //                 <span>Bye Week (NO GAME)</span>
    //               ) : row.home.id === row.away.id ? (
    //                 <span>TBD</span>
    //               ) : (
    //                 <a href={'/team/' + row.away?.id}>
    //                   <i
    //                     style={{
    //                       color: row.away?.color.hexCode,
    //                       marginRight: 10,
    //                       padding: 2,
    //                       backgroundColor: row.away?.color.hexCode === '#FFFFFF' ? 'black' : 'white'
    //                     }}
    //                     className="fas fa-tshirt"
    //                   ></i>
    //                   {row.away?.name}
    //                 </a>
    //               )}
    //             </TableCell>
    //             <TableCell>{row.byeWeek ? format(new Date(row.gameAt), 'MM/dd/yyyy') : row.displayTime}</TableCell>
    //             <TableCell>{row.field?.name}</TableCell>
    //             <TableCell>{row.referee?.fullName}</TableCell>
    //             <TableCell>
    //               {row.finalized ? (
    //                 <span>
    //                   {row.homeScore} - {row.awayScore}
    //                 </span>
    //               ) : null}
    //             </TableCell>
    //           </TableRow>
    //         ))}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    // </Container>
  );
}
