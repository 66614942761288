import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Button } from '@material-ui/core';
import Sponsors from './Sponsors';
import SeasonDetails from './SeasonDetails';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import SponsorsList from "./SponsorList/SponsorsList";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: 'url(https://www.leaguewarrior.com/247ksplash-min.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '40vh',
    padding: '80',
    alignItems: 'center'
  },
  font: {
    position: 'relative',
    top: '10%',
    width: '100%',
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'none'
  },
  font2: {
    position: 'relative',
    top: '20%',
    width: '100%',
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'none'
  },
  button: {
    position: 'absolute',
    top: '20%',
    width: '100%',
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'none'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    alignItems: 'center'
  },
  paper2: {
    margin: theme.spacing(50, 80),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'blue'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  card: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
}));

const isRegistrationOpen = () => {
  const now = DateTime.now().setZone('America/New_York');
  const registrationEnd = DateTime.fromISO('2024-07-24').setZone('America/New_York'); // FIXME: stop hardcoding
  // console.log('NewYorkTime', now.toLocaleString(DateTime.DATETIME_SHORT));
  // console.log('RegistratinEnd', registrationEnd.toLocaleString(DateTime.DATETIME_SHORT));
  return now <= registrationEnd;
};

const getRegistrationButton = () => {
  const classes = useStyles();
  if (isRegistrationOpen()) {
    return (
      <Link to="registration">
        <Button type="submit" variant="contained" color="primary" className={classes.submit}>
          Register
        </Button>
      </Link>
    );
  } else {
    return (
      <Button type="submit" variant="contained" color="primary" className={classes.submit}>
        Registration is closed
      </Button>
    );
  }
};

const Main = () => {
  const classes = useStyles();
  const history = useHistory();
  // const playerId = localStorage.getItem('user-id');
  //
  // if (playerId) {
  //   history.push('/dashboard');
  // }
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {/*<Grid item xs={false} sm={7} md={10} className={classes.image} >*/}

      {/*    <Grid container item xs={5} spacing={25}>*/}
      {/*        <Link to="registration"><button>registration</button></Link>*/}
      {/*    </Grid>*/}

      {/*</Grid>*/}
      {/*<Grid item xs={9}>*/}
      {/*  <BGImage bg="https://www.leaguewarrior.com/247field.jpg" tint="#808080" />*/}
      {/*</Grid>*/}
      <Grid item xs={12} className={classes.image}>
        <Typography gutterBottom variant="h3" component="h3" className={classes.font}>
          <span>Fall Registration </span>
          <span>{isRegistrationOpen() ? <span>Now Open!</span> : <span>Closed</span>}</span>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h4" component="h4" className={classes.font2}>
              <Grid item xs={12}>
                {getRegistrationButton()}
              </Grid>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h4" component="h4" className={classes.font2}>
              <Grid item xs={12}>
                <Link to="event-details">
                  <Button type="submit" variant="contained" color="primary" className={classes.submit}>
                    Learn More
                  </Button>
                </Link>
              </Grid>
            </Typography>
          </Grid>
        </Typography>
      </Grid>
      <SeasonDetails />
      {/*<Grid item xs={3}>*/}
      {/*  <Link rel="noopener noreferrer" href="https://www.wendellhoover.com/agent-bio/JasonMcMullen" target="_blank">*/}
      {/*    <Card className={classes.card}>*/}
      {/*      <CardActionArea>*/}
      {/*        <CardMedia*/}
      {/*          className={classes.media}*/}
      {/*          image="https://www.247kickball.com/assets/iron-valley-logo-small.png"*/}
      {/*          title="Jason McMullen Iron Valley"*/}
      {/*        >*/}
      {/*          {' '}*/}
      {/*          <CardActions>*/}
      {/*            <a href="www.,google.com" />*/}
      {/*          </CardActions>*/}
      {/*        </CardMedia>*/}
      {/*        <CardContent></CardContent>*/}
      {/*      </CardActionArea>*/}
      {/*    </Card>*/}
      {/*  </Link>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={3}>*/}
      {/*  <Card>*/}
      {/*    <CardContent>*/}
      {/*      <Typography variant="body2" color="textSecondary" component="p">*/}
      {/*        sponsor 2*/}
      {/*      </Typography>*/}
      {/*    </CardContent>*/}
      {/*  </Card>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={3}>*/}
      {/*  <Card>*/}
      {/*    <CardContent>*/}
      {/*      <Typography variant="body2" color="textSecondary" component="p">*/}
      {/*        sponsor 3*/}
      {/*      </Typography>*/}
      {/*    </CardContent>*/}
      {/*  </Card>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={3}>*/}
      {/*  <Card>*/}
      {/*    <CardContent>*/}
      {/*      <Typography variant="body2" color="textSecondary" component="p">*/}
      {/*        sponsor 4*/}
      {/*      </Typography>*/}
      {/*    </CardContent>*/}
      {/*  </Card>*/}
      {/*</Grid>*/}
      <SponsorsList />
    </Grid>

  );
};

export default Main;
