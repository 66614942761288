import {
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
  Button,
  Modal,
  FormHelperText
} from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Questionnaire } from '../../api/models/Questionnaire';
import { QuestionnaireResultInput } from '../../api/models/QuestionnaireResultInput';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  formControl: {
    minWidth: 300,
    marginBottom: 10
  },
  paper: {
    position: 'absolute',
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  modalStyle: {
    position: 'absolute',
    display: 'block',
    overflowY: 'scroll'
  }
}));

function getModalStyle() {
  const top = 10;
  const left = 10;
  const right = 10;

  return {
    top: `${top}%`,
    left: `${left}%`,
    right: `${right}%`
  };
}
export interface QuestionnaireRegInput extends QuestionnaireResultInput {
  required?: boolean;
}

type QuestionnaireInputProps = {
  questionnaireResultInput: QuestionnaireRegInput; // User answered question
  questionnaire: Questionnaire; // Questinnaire from API
  onQuestionnaireInput(inputs: QuestionnaireRegInput): void; // Function for parent
  formSubmitted: boolean; // Triggers form validation
};

export default function QuestionnaireInput({
  questionnaireResultInput,
  questionnaire,
  onQuestionnaireInput,
  formSubmitted
}: QuestionnaireInputProps) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [result, setResult] = useState<string>(questionnaireResultInput.result); // State
  const [filename, setFilename] = useState<string>(questionnaireResultInput.filename || ''); // State
  const [open, setOpen] = useState(false); // State
  const [error, setError] = useState('');

  const onTextChange = (event: ChangeEvent<{ value: unknown }>) => {
    setResult(event.target.value as string); // For this local component
    onQuestionnaireInput({
      questionnaireId: questionnaire.id,
      result,
      filename: filename || undefined,
      required: questionnaire.required
    }); // For parent component
  };

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked ? 'true' : 'false';
    setResult(checked);
    onQuestionnaireInput({
      questionnaireId: questionnaire.id,
      result: checked,
      filename: filename || undefined,
      required: questionnaire.required
    }); // For parent component
  };

  const onPolicyAccept = () => {
    handleClose();
    const accepted = 'true';
    setResult(accepted);
    onQuestionnaireInput({
      questionnaireId: questionnaire.id,
      result: accepted,
      filename: filename || undefined,
      required: questionnaire.required
    }); // For parent component
  };

  const onFileChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { name, type } = (event.target as any).files[0];
    setFilename(name);
    setResult('true');
    onQuestionnaireInput({
      questionnaireId: questionnaire.id,
      result: 'true',
      filename: name,
      required: questionnaire.required
    }); // For parent component
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log('formsubmitted?', formSubmitted);
    console.log('q checking for required');
    if (formSubmitted && questionnaire.required && !result) {
      setError('Response is required');
    } else {
      setError('');
    }
  }, [result, formSubmitted]);

  return (
    <div className={classes.root}>
      {questionnaire.propertyType === 'TEXT_INPUT' ? (
        <FormControl className={classes.formControl} error={!!error}>
          <InputLabel id="text-input-label">{questionnaire.name}</InputLabel>
          <Input id="text-input-label" value={result} onChange={onTextChange} />
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      ) : null}
      {questionnaire.propertyType === 'TEXT_AREA' ? (
        <FormControl className={classes.formControl} error={!!error}>
          <TextareaAutosize minRows={3} value={result} onChange={onTextChange} placeholder={questionnaire.name} />
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      ) : null}
      {questionnaire.propertyType === 'CHECKBOX' ? (
        <FormControl className={classes.formControl} error={!!error}>
          <FormControlLabel
            control={
              <Checkbox checked={result === 'true'} onChange={onCheckboxChange} name="checkedB" color="primary" />
            }
            label={questionnaire.name}
          />
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      ) : null}
      {questionnaire.propertyType === 'POLICY' ? (
        <FormControl className={classes.formControl} error={!!error}>
          <div>
            {questionnaire.name}
            <Button style={{ marginLeft: 10 }} variant="contained" color="primary" onClick={handleOpen}>
              View
            </Button>
            {result ? <span style={{ marginLeft: 10, color: 'green' }}>Accepted!</span> : ''}
          </div>
          <FormHelperText>{error}</FormHelperText>
          <Modal open={open} onClose={handleClose} className={classes.modalStyle}>
            <div style={modalStyle} className={classes.paper}>
              <h2 id="simple-modal-title">{questionnaire.description}</h2>
              <p id="simple-modal-description" dangerouslySetInnerHTML={{ __html: questionnaire.policyText }}></p>
              <Button variant="contained" color="primary" onClick={onPolicyAccept}>
                Accept Policy
              </Button>
            </div>
          </Modal>
        </FormControl>
      ) : null}
      {questionnaire.propertyType === 'FILE' ? (
        <FormControl className={classes.formControl} error={!!error}>
          <div>{questionnaire.name}</div>
          {filename ? (
            <div>{filename}</div>
          ) : (
            <Button variant="contained" component="label">
              Upload File
              <input type="file" hidden onChange={onFileChange} />
            </Button>
          )}
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      ) : null}
    </div>
  );
}
