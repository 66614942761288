import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useQuery } from '@apollo/client';
import { GET_TEAM } from '../../queries';
import { Divider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Roster from './Roster';
import TeamHeader from './TeamHeader';
import Grid from '@material-ui/core/Grid';
import TeamSchedule from './TeamSchedule';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    box: {
      display: 'grid',
      placeItems: 'center'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    container: {
      height: 100,
      align: 'center'
    },
    avatar: {
      backgroundColor: 'white'
    }
  })
);

export default function TeamCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const teamId = props.match.params.teamId;
  let captain = false;
  const { loading, error, data } = useQuery(GET_TEAM, {
    variables: {
      id: teamId
    }
  });

  if (loading) {
    return <p>Loading ...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const captainId = data.team?.captain.id;
  const captainName = data.team?.captain.fullName;

  if (captainId === localStorage.getItem('user-id')) {
    captain = true;
  }

  return (
    <div className={classes.box}>
      <Grid container spacing={2} justify="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={12}>
          <TeamHeader team={data?.team} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TeamSchedule games={data?.team?.games} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Roster users={data?.team?.users} teamName={data?.team?.name} captain={data?.team?.captain} />
        </Grid>
      </Grid>
    </div>
  );
}
