import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import { Division } from '../../api/models/Division';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  formControl: {
    minWidth: 300,
    marginBottom: 10
  }
}));

type FreeAgentInputsProps = {
  division?: Division[]; // User selected divisions
  divisions: Division[]; // Divisions from API
  onFreeAgentInputs(inputs: Division[]): void; // Function for parent
};

export default function FreeAgentInputs(input: FreeAgentInputsProps) {
  const classes = useStyles();

  const { divisions, onFreeAgentInputs } = input; // Properties
  const [division, setDivision] = useState<Division[]>(input.division || []); // State

  const onChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDivision(event.target.value as Division[]); // For this local component
    onFreeAgentInputs(event.target.value as Division[]); // For parent component
  };

  return (
    <div className={classes.root}>
      <div>What divison(s) would you be interested in playing in?</div>
      <FormControl className={classes.formControl}>
        <InputLabel id="free-agent-divisions-label">Divisions</InputLabel>
        <Select
          labelId="free-agent-divisions-label"
          id="free-agent-divisions"
          multiple
          value={division}
          onChange={onChange}
          input={<Input />}
          renderValue={(selected) => (selected as any[]).map((item) => item.name).join(',')}
          MenuProps={MenuProps}
        >
          {divisions.map((item) => (
            <MenuItem key={item.id} value={item as unknown as string[]}>
              <Checkbox checked={division.includes(item)} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
