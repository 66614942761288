import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: 'url(https://www.leaguewarrior.com/247ksuf.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
    // backgroundColor: theme.palette.secondary.main,
    // backgroundColor: 'lightgray'
  }
}));

const Header = ({ logout, token, playerId }) => {
  const classes = useStyles();
  const name = localStorage.getItem('user-name');
  return (
    <div>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="/">
          <img
            alt=""
            src="https://www.leaguewarrior.com/247logo.png"
            width="100"
            height="30"
            className="d-inline-block align-top"
          />{' '}
        </Navbar.Brand>
        <Nav className="mr-auto">
          <NavDropdown title="Rules" id="basic-nav-dropdown">
            <NavDropdown.Item className={classes.avatar} href="/rules">
              Highlights
            </NavDropdown.Item>
            <NavDropdown.Item
              className={classes.avatar}
              href="https://www.247kickball.com/assets/247KickballOfficialRulebook.pdf"
            >
              Full Rulebook
            </NavDropdown.Item>
          </NavDropdown>
          {/*<Nav.Link href="/locations">Locations</Nav.Link>*/}
          <NavDropdown title="Season Details" id="basic-nav-dropdown">
            {playerId ? (
              <NavDropdown.Item className={classes.avatar} href={'/dashboard/' + playerId}>
                Dashboard
              </NavDropdown.Item>
            ) : null}
            <NavDropdown.Item className={classes.avatar} href="/standings">
              Standings
            </NavDropdown.Item>
            <NavDropdown.Item className={classes.avatar} href="/schedule">
              Schedule
            </NavDropdown.Item>
            <NavDropdown.Item className={classes.avatar} href="/playoffs">
              Playoffs
            </NavDropdown.Item>
          </NavDropdown>
          {/* <Nav.Link href="/standings">Standings</Nav.Link>
          <Nav.Link href="/schedule">Schedule</Nav.Link> */}
        </Nav>
        {/*<Link to="registration">*/}
        {/*  <button>registration</button>*/}
        {/*</Link>*/}
        <Nav>{!token && <Nav.Link href="/login">Login</Nav.Link>}</Nav>
        {/*<Link to="/books"><button>books</button></Link>*/}
        {/*{*/}
        {/*    !token &&*/}
        {/*    <Link to="login"><button>login</button></Link>*/}
        {/*}*/}
        {/*{*/}
        {/*    token &&*/}
        {/*    <Link to="newbook"><button>add book</button></Link>*/}
        {/*}*/}
        {/*{*/}
        {/*    token &&*/}
        {/*    <Link to="recommend"><button>recommend</button></Link>*/}
        {/*}*/}
        {/*{*/}
        {/*    token &&*/}
        {/*    <Link to="users"><Button>Users</Button></Link>*/}
        {/*}*/}
        {token && (
          <Nav className="align-content-end">
            <NavDropdown alignRight id="basic-nav-dropdown" title={name} flip>
              {' '}
              <NavDropdown.Item className={classes.avatar} href="/dashboard">
                Dashboard
              </NavDropdown.Item>
              <NavDropdown.Item
                className={classes.avatar}
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        )}
        {/*{token && (*/}
        {/*  <button*/}
        {/*    onClick={() => {*/}
        {/*      logout();*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Logout*/}
        {/*  </button>*/}
        {/*)}*/}
      </Navbar>
    </div>
  );
};

export default Header;
