import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Divider, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import { format } from 'date-fns';
import { createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'visible'
    },
    table: {
      minWidth: 650,
      overflowX: 'visible'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    container: {
      height: 100,
      align: 'center'
    },
    avatar: {
      backgroundColor: 'white'
    }
  })
);

export default function ScheduleCard(props) {
  const classes = useStyles();

  const schedule = props.games;
  const date = schedule[0].gameDate;
  const [expanded, setExpanded] = React.useState(!schedule[0].finalized);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Card className={classes.root}>
        <CardHeader
          title={date}
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Home</TableCell>
                  <TableCell>Away</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Field</TableCell>
                  <TableCell>Ref</TableCell>
                  <TableCell>Results</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schedule?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <a href={'/team/' + row.home.id}>
                        <i
                          style={{
                            color: row.home?.color.hexCode,
                            marginRight: 10,
                            padding: 2,
                            backgroundColor: row.home?.color.hexCode === '#FFFFFF' ? 'black' : 'white'
                          }}
                          className="fas fa-tshirt"
                        ></i>
                        {row.home?.name}
                      </a>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.byeWeek ? (
                        <span>Bye Week (NO GAME)</span>
                      ) : row.home?.id === row.away?.id ? (
                        <span>TBD</span>
                      ) : (
                        <a href={'/team/' + row.away?.id}>
                          <i
                            style={{
                              color: row.away?.color.hexCode,
                              marginRight: 10,
                              padding: 2,
                              backgroundColor: row.away?.color.hexCode === '#FFFFFF' ? 'black' : 'white'
                            }}
                            className="fas fa-tshirt"
                          ></i>
                          {row.away?.name}
                        </a>
                      )}
                    </TableCell>
                    <TableCell>{row.byeWeek ? format(new Date(row.gameAt), 'MM/dd/yyyy') : row.displayTime}</TableCell>
                    <TableCell>{row.field?.name}</TableCell>
                    <TableCell>{row.referee?.fullName}</TableCell>
                    <TableCell>
                      {row.finalized ? (
                        <span>
                          {row.homeScore} - {row.awayScore}
                        </span>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}
