import { useQuery } from '@apollo/client';
import {
  Container,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import React from 'react';
import { GET_EVENT } from '../../queries';
import { sortBy } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  table: {}
}));

export default function Standings({ eventId }) {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_EVENT, {
    variables: {
      id: eventId
    }
  });

  const standings = data?.event?.standings;
  const rows = standings?.[0]?.standings;

  if (loading) {
    return <p>Loading ...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!standings || standings.length == 0) {
    return (
        <Container style={{ alignItems: 'center' }}>
          <div>
            <div>
              <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Team</TableCell>
                      <TableCell align="right">Record(W/L/T)</TableCell>
                      {/*<TableCell align="right">Losses</TableCell>*/}
                      {/*<TableCell align="right">Ties</TableCell>*/}
                      <TableCell align="right">Points</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Container>
    );
  }

  return (
      <Container style={{ alignItems: 'center' }}>
        <div>
          {standings.map((division, index) => (
              <div>
                {division.standings?.length ? (
                    <TableContainer component={Paper} key={index} style={{ marginBottom: 20 }}>
                      <Typography variant="h5">{division.division}</Typography>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Team</TableCell>
                            <TableCell align="right">Record(W/L/T)</TableCell>
                            {/*<TableCell align="right">Losses</TableCell>*/}
                            {/*<TableCell align="right">Ties</TableCell>*/}
                            <TableCell align="right">Points</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {division.standings.map((row) => (
                              <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                  <a href={'/team/' + row.team.id}>
                                    <i
                                        style={{
                                          color: row.team?.color.hexCode,
                                          marginRight: 10,
                                          padding: 2,
                                          backgroundColor: row.team?.color.hexCode === '#FFFFFF' ? 'black' : 'white'
                                        }}
                                        className="fas fa-tshirt"
                                    ></i>
                                    {row.team?.name}
                                  </a>{' '}
                                  ({row.record})
                                </TableCell>
                                <TableCell align="right">
                                  {row.wins}/{row.losses}/{row.ties}
                                </TableCell>
                                {/*<TableCell align="right">{row.losses}</TableCell>*/}
                                {/*<TableCell align="right">{row.ties}</TableCell>*/}
                                <TableCell align="right">
                                  {row.pointsScored}PS {row.pointsAllowed}PA
                                </TableCell>
                              </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                ) : null}
              </div>
          ))}
        </div>
      </Container>
  );
}
