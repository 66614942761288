import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_EVENT_DETAILS } from '../../queries';
import { Event } from '../../api/models/Event';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    alignItems: 'center'
  }
}));

export default function EventDetails(props) {
  //const leagueId = 'ev_h7Dp6nnKNC';
  const classes = useStyles();
  const leagueId = 'ev_W27thtxqF3';
  const [loadingResult, result] = useLazyQuery(GET_EVENT_DETAILS, { variables: { id: leagueId } });
  useEffect(() => {
    loadingResult();
  }, [loadingResult]);

  if (!result.called || result.loading) {
    return <div>loading...</div>;
  }

  const event: Event = result?.data?.event;

  return (
    <div>
      <Paper className={classes.paper}>
        <div dangerouslySetInnerHTML={{ __html: event.details }} />
      </Paper>
    </div>
  );
}
