import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import React from 'react';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('user-token');

  if (token) {
    const parseJwt1 = parseJwt(token);
    const date = new Date();
    const seconds = date.getTime() / 1000;
    if (seconds > parseJwt1.exp) {
      localStorage.clear();
    }
  }

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `bearer ${token}` : null
      }
    };
  }
});

const httpLink = new HttpLink({ uri: 'https://service.brewdoglabs.com/graphql' });
// const httpLink = new HttpLink({ uri: 'https://service.brewdoglabs.com/test/graphql' });
// const httpLink = new HttpLink({ uri: 'http://localhost:8048/graphql' });

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink as any) as any as ApolloLink
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);
