import React, { useEffect, useState } from 'react';
import { Checkbox, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm, Controller } from 'react-hook-form';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import { SportsBasketball } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import Link from '@material-ui/core/Link';
import { gql, useMutation } from '@apollo/client';
import DisplayAlert from './DisplayAlert';
import { useHistory } from 'react-router-dom';

// createUser(input:{
//   firstName:"bryan"
//   lastName:"lichtenwalner"
//   email:"bryan@brewdoglabs.com"
//   phone:"6104165784"
//   password:"password"
//   gender: MALE
//   dob:"1984-03-22"
//   address:{
//     street:"123"
//     city: "nashville"
//     state:"tn"
//     zip:"37067"
//   }
// }){
//   id
// }
// }

const CREATE_USER = gql`
  mutation createUser($input: createUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

type User = {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  password?: string;
  gender?: string;
  dob?: string;
  address?: address;
};

type address = {
  street: string;
  city: string;
  state: string;
  zip: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '80vh'
  },
  image: {
    backgroundImage: 'url(https://www.leaguewarrior.com/247ksuf.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'yellow'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  textField: {
    width: '100%'
  }
}));

const SignUpForm = () => {
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [createUser, newUser] = useMutation(CREATE_USER, {
    errorPolicy: 'all',
    onError: (error) => {
      console.log('apollo error' + error.message);
    }
  });

  function getHumanError(str) {
    return str.split(':')[1];
  }

  const history = useHistory();
  const classes = useStyles();
  const { handleSubmit, control } = useForm();

  useEffect(() => {
    if (newUser.error) {
      setErrMsg(getHumanError(newUser.error.graphQLErrors[0].message));
      setOpen(true);
    }
    if (newUser.data) {
      history.push('/login');
    }
  }, [history, newUser.data]);

  const onSubmit = (data) => {
    if (!data.birthDate) {
      data.birthDate = new Date();
    }
    const formattedDate = format(data.birthDate, 'yyyy-MM-dd');
    setOpen(false);
    const a: address = {
      city: data.city,
      state: data.state,
      zip: data.zipCode,
      street: data.street
    };
    const u: User = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      phone: data.phoneNum,
      gender: data.gender,
      dob: formattedDate,
      address: a
    };

    createUser({
      variables: { input: u }
    });
  };

  return (
    <div className={classes.root}>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        {open && <DisplayAlert message={errMsg} />}
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <SportsBasketball />
            </Avatar>
            <Typography component="h1" variant="h5">
              Create a new 247Kickball Account
            </Typography>
            <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={6}>
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="First Name"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                    rules={{ required: 'First name required' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                    rules={{ required: 'Last name required' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type="email"
                      />
                    )}
                    rules={{
                      required: 'Email required'
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Password"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type="password"
                      />
                    )}
                    rules={{ required: 'Password required' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="street"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Street Address"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type="address"
                      />
                    )}
                    rules={{ required: 'Street Address required' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="city"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="City"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type="city"
                      />
                    )}
                    rules={{ required: 'Street Address required' }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel htmlFor="age-native-simple">State</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="AL">AL</MenuItem>
                        <MenuItem value="AK">AK</MenuItem>
                        <MenuItem value="AZ">AZ</MenuItem>
                        <MenuItem value="AR">AR</MenuItem>
                        <MenuItem value="CA">CA</MenuItem>
                        <MenuItem value="CO">CO</MenuItem>
                        <MenuItem value="CT">CT</MenuItem>
                        <MenuItem value="DE">DE</MenuItem>
                        <MenuItem value="FL">FL</MenuItem>
                        <MenuItem value="GA">GA</MenuItem>
                        <MenuItem value="HI">HI</MenuItem>
                        <MenuItem value="ID">ID</MenuItem>
                        <MenuItem value="IL">IL</MenuItem>
                        <MenuItem value="IN">IN</MenuItem>
                        <MenuItem value="IA">IA</MenuItem>
                        <MenuItem value="KS">KS</MenuItem>
                        <MenuItem value="KY">KY</MenuItem>
                        <MenuItem value="LA">LA</MenuItem>
                        <MenuItem value="ME">ME</MenuItem>
                        <MenuItem value="MD">MD</MenuItem>
                        <MenuItem value="MA">MA</MenuItem>
                        <MenuItem value="MI">MI</MenuItem>
                        <MenuItem value="MN">MN</MenuItem>
                        <MenuItem value="MS">MS</MenuItem>
                        <MenuItem value="MO">MO</MenuItem>
                        <MenuItem value="MT">MT</MenuItem>
                        <MenuItem value="NE">NE</MenuItem>
                        <MenuItem value="NV">NV</MenuItem>
                        <MenuItem value="NH">NH</MenuItem>
                        <MenuItem value="NJ">NJ</MenuItem>
                        <MenuItem value="NM">NM</MenuItem>
                        <MenuItem value="NY">NY</MenuItem>
                        <MenuItem value="NC">NC</MenuItem>
                        <MenuItem value="ND">ND</MenuItem>
                        <MenuItem value="OH">OH</MenuItem>
                        <MenuItem value="OK">OK</MenuItem>
                        <MenuItem value="OR">OR</MenuItem>
                        <MenuItem value="PA">PA</MenuItem>
                        <MenuItem value="RI">RI</MenuItem>
                        <MenuItem value="SC">SC</MenuItem>
                        <MenuItem value="SD">SD</MenuItem>
                        <MenuItem value="TN">TN</MenuItem>
                        <MenuItem value="TX">TX</MenuItem>
                        <MenuItem value="UT">UT</MenuItem>
                        <MenuItem value="VT">VT</MenuItem>
                        <MenuItem value="VA">VA</MenuItem>
                        <MenuItem value="WA">WA</MenuItem>
                        <MenuItem value="WV">WV</MenuItem>
                        <MenuItem value="WI">WI</MenuItem>
                        <MenuItem value="WY">WY</MenuItem>
                      </Select>
                    )}
                    control={control}
                    name="state"
                    defaultValue={'PA'}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="zipCode"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Zip Code"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type="string"
                        // className={classes.numberInput}
                        inputProps={{
                          maxLength: 5,
                          pattern: '[0-9]*'
                        }}
                      />
                    )}
                    rules={{ required: 'Zip code required', minLength: 5 }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    name="phoneNum"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Phone Number"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type="tel"
                        // className={classes.numberInput}
                        inputProps={{
                          pattern: '[0-9]*'
                        }}
                      />
                    )}
                    rules={{ required: 'Phone number required' }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      name="birthDate"
                      control={control}
                      render={({ field: { onChange, value }, ...rest }) => (
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker"
                          label="Birth Date"
                          format="MM/dd/yyyy"
                          value={value}
                          required
                          maxDate={new Date()}
                          onChange={onChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="age-native-simple">Gender</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="MALE">Male</MenuItem>
                        <MenuItem value="FEMALE">Female</MenuItem>
                      </Select>
                    )}
                    control={control}
                    name="gender"
                    defaultValue={'MALE'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <label>
                    I accept the{' '}
                    <Link href="/toc" variant="body2">
                      {'terms and conditions'}
                    </Link>
                  </label>
                  <Controller
                    name="termsAndConditions"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox name="das" onChange={onChange} checked={value} required />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <Button type="submit" variant="contained" color="primary">
                      Signup
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUpForm;
