import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useQuery } from '@apollo/client';
import { GET_TEAM } from '../../queries';
import { Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 600
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    box: {
      display: 'grid',
      placeItems: 'center'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    card: {
      maxWidth: 600,
      alignItems: 'center'
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    container: {
      height: 100,
      align: 'center'
    },
    avatar: {
      backgroundColor: 'white'
    },
    table: {}
  })
);

export default function TeamSchedule(props) {
  const classes = useStyles();
  const games = props.games;
  return (
    <div>
      <Typography variant="h6">Schedule</Typography>

      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Home</TableCell>
            <TableCell>Away</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Field</TableCell>
            <TableCell>Result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {games.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                <a href={'/team/' + row.home.id}>
                  <i
                    style={{
                      color: row.home?.color.hexCode,
                      marginRight: 10,
                      padding: 2,
                      backgroundColor: row.home?.color.hexCode === '#FFFFFF' ? 'black' : 'white'
                    }}
                    className="fas fa-tshirt"
                  ></i>
                  {row.home?.name}
                </a>
              </TableCell>
              <TableCell component="th" scope="row">
                {row.byeWeek ? (
                  <span>Bye Week (NO GAME)</span>
                ) : (
                  <a href={'/team/' + row.away?.id}>
                    <i
                      style={{
                        color: row.away?.color.hexCode,
                        marginRight: 10,
                        padding: 2,
                        backgroundColor: row.away?.color.hexCode === '#FFFFFF' ? 'black' : 'white'
                      }}
                      className="fas fa-tshirt"
                    ></i>
                    {row.away?.name}
                  </a>
                )}
              </TableCell>
              <TableCell>{row.byeWeek ? format(new Date(row.gameAt), 'MM/dd/yyyy') : row.displayTime}</TableCell>
              <TableCell>{row.field?.name}</TableCell>
              <TableCell>
                {row.finalized ? (
                  <span>
                    {row.homeScore} - {row.awayScore}
                  </span>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
