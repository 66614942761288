import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Grid, Link } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import PinDropIcon from '@material-ui/icons/PinDrop';
import SportsIcon from '@material-ui/icons/Sports';
import { gql, useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'blue',
    alignItems: 'center'
  },
  pos: {
    marginBottom: 12
  }
}));

const GET_EVENT = gql`
  query event($id: ID!) {
    event(id: $id) {
      id
      startDate
      endDate
      fees {
        startDate
        endDate
        amount
      }
    }
  }
`;

export default function SeasonDetails(props) {
  //local testing: ev_VlWwYYTE5p
  // const leagueId = 'ev_h7Dp6nnKNC';
  //const leagueId = 'ev_pUOz0gKoUj';
  const leagueId = 'ev_W27thtxqF3';
  const [loadingResult, result] = useLazyQuery(GET_EVENT, { variables: { id: leagueId } });
  useEffect(() => {
    loadingResult();
  }, [loadingResult]);

  const classes = useStyles();

  if (!result.called || result.loading) {
    return <div>loading...</div>;
  }

  const event = result?.data?.event;

  const feeAmt = event?.fees[0].amount;

  let date = new Date(event?.startDate);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  let formattedDate = format(date, 'MMMM dd');
  const startDate = formattedDate;

  date = new Date(event?.endDate);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  formattedDate = format(date, 'MMMM dd');
  const endDate = formattedDate;

  date = new Date(event?.endDate);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  date.setDate(date.getDate() + 7);
  formattedDate = format(date, 'MMMM dd');
  const playoffStart = formattedDate;

  date = new Date(event?.endDate);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  date.setDate(date.getDate() + 14);
  formattedDate = format(date, 'MMMM dd');
  const playoffEnd = formattedDate;

  date = new Date(event?.fees[0].startDate);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  formattedDate = format(date, 'MMMM dd');
  const regStart = formattedDate;

  date = new Date(event?.fees[0].endDate);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  formattedDate = format(date, 'MMMM dd');
  const regEnd = formattedDate;

  return (
    <Grid xs={12} container direction="row" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs>
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Season Dates
            </Typography>
            <Avatar className={classes.avatar}>
              <CalendarTodayIcon />
            </Avatar>
            <Typography variant="body1">
              Season: {startDate} - {endDate}
            </Typography>
            <Typography variant="body1">
              Playoffs: {playoffStart} - {playoffEnd}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Registration
            </Typography>
            <Avatar className={classes.avatar}>
              <HowToRegIcon />
            </Avatar>
            <Typography>
              Open: {regStart} - {regEnd}
            </Typography>
            <Typography>Standard Registration: ${feeAmt}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Location
            </Typography>
            <Avatar className={classes.avatar}>
              <PinDropIcon />
            </Avatar>
            <Typography>
              <Link href="/locations">Skyline Sports Complex (City Island)</Link>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Team Details
            </Typography>
            <Avatar className={classes.avatar}>
              <SportsIcon />
            </Avatar>
            <Typography>Coed 15 Player Minimum</Typography>
            <Typography>Must Be 21 at Season Start</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
