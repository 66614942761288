import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { User } from './api/models/User';
import Recommend from './components/Authors/Recommend';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import Header from './components/Header';
import Locations from './components/Locations/Locations';
import Main from './components/Main';
import Notification from './components/Notification';
import Registration from './components/Registration/Registration';
import ResetPassword from './components/ResetPassword/ResetPassword';
import Rules from './components/Rules/Rules';
import SignInForm from './components/SignIn/SignInForm';
import SignUpForm from './components/SignUp/SignUpForm';
import Users from './components/Users/Users';
import EventDetails from './components/EventDetails/EventDetails';
import Toc from './components/EventDetails/Toc';
import Standings from './components/Standings/Standings';
import Team from './components/Team/Team';
import Schedule from './components/Schedule/Schedule';
import PlayerDashboard from './components/PlayerDashboard/PlayerDashboard';
import React from 'react';
import RefundPolicy from './components/EventDetails/RefundPolicy';
import TeamCard from './components/Team/TeamCard';
import Playoffs from './components/Playoffs/Playoffs';

const App = () => {
  const [notification, setNotification] = useState(null);
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<User>('' as any);
  const client = useApolloClient();

  // FIXME: STOP HARDCODING THIS SHIT!!!!
  // const eventId="ev_VlWwYYTE5p"
  const eventId = 'ev_W27thtxqF3';
  const leagueId = '123';

  // const updateCacheWith = (addedBook) => {
  //   const includedIn = (set, object) => set.map((p) => p.id).includes(object.id);

  //   const dataInStore = client.readQuery({ query: ALL_BOOKS });
  //   if (dataInStore && !includedIn(dataInStore.allBooks, addedBook)) {
  //     client.writeQuery({
  //       query: ALL_BOOKS,
  //       data: { allBooks: dataInStore.allBooks.concat(addedBook) }
  //     });
  //   }
  // };

  useEffect(() => {
    const token = window.localStorage.getItem('user-token');
    if (token) {
      setToken(token as any);
    }
    const user = window.localStorage.getItem('user');
    if (user) {
      setUser(user as any);
    }
  }, []);

  const notifyWith = (message, type = 'success', timer = 5000) => {
    // setNotification({ message, type });  // TODO: { message, type } is not a valid SetStateAction
    setNotification((prevState: any) => ({ ...prevState, message, type })); // TODO: does this work?
    setTimeout(() => {
      setNotification(null);
    }, timer);
  };

  const logout = () => {
    setToken(null);
    localStorage.clear();
    client.resetStore();

    notifyWith('succeeded log out');
  };

  return (
    <div>
      <Header logout={logout} token={token} playerId={user?.id} />
      <Notification notification={notification} />

      <Switch>
        {/*<Route path="/login">*/}
        {/*    <LoginForm token={token} setToken={setToken} notifyWith={notifyWith} />*/}
        {/*</Route>*/}
        <Route path="/login">
          <SignInForm token={token} setToken={setToken} setUser={setUser} notifyWith={notifyWith} />
        </Route>
        <Route path="/locations">
          <Locations />
        </Route>
        <Route path="/rules">
          <Rules />
        </Route>
        <Route path="/recommend">
          <Recommend token={token} />
        </Route>
        <Route path="/users">
          <Users token={token} />
        </Route>
        <Route path="/sign-up">
          <SignUpForm />
        </Route>
        <Route path="/registration">
          <Registration token={token} notifyWith={notifyWith} user={user} leagueId={leagueId} eventId={eventId} />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/event-details">
          <EventDetails />
        </Route>
        <Route path="/refund-policy">
          <RefundPolicy />
        </Route>
        <Route path="/toc">
          <Toc />
        </Route>
        <Route path="/reset-password/:token" render={(props) => <ResetPassword {...props} />} />
        <Route path="/standings">
          <Standings eventId={eventId} />
        </Route>
        <Route path="/schedule">
          <Schedule eventId={eventId} />
        </Route>
        <Route path="/playoffs">
          <Playoffs />
        </Route>
        <Route path="/teamcard/:teamId" render={(props) => <TeamCard {...props} />} />
        <Route path="/team/:teamId" render={(props) => <TeamCard {...props} />} />
        <Route path="/dashboard">
          <PlayerDashboard token={token} eventId={eventId} />
        </Route>
        <Main />
      </Switch>

      {/*<Footer />*/}
    </div>
  );
};

export default App;
