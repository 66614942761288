import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useQuery } from '@apollo/client';
import { GET_TEAM } from '../../queries';
import { Divider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 600
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    box: {
      display: 'grid',
      placeItems: 'center'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    card: {
      maxWidth: 600,
      alignItems: 'center'
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    container: {
      height: 100,
      align: 'center'
    },
    avatar: {
      backgroundColor: 'white'
    }
  })
);

export default function TeamHeader(props) {
  const classes = useStyles();
  const team = props.team;
  const captainName = team.captain.fullName;
  const headerText = team.name + ' (' + team.record + ')';

  return (
    <div>
      <Card>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              <i
                style={{
                  color: team.color.hexCode,
                  //marginRight: 10,
                  padding: 2,
                  backgroundColor: team.color?.hexCode === '#FFFFFF' ? 'black' : 'white'
                }}
                className="fas fa-tshirt"
              ></i>
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={headerText}
          // subheader="Since 2021"
        />
        {/*<CardMedia className={classes.media} image="/static/images/cards/paella.jpg" title="Paella dish" />*/}
        <CardContent>
          <Typography variant="body2">Captain: {captainName}</Typography>
          <Divider />
          <br />
          <Typography variant="body2">Record: {team.record}</Typography>
          {/*  <Table aria-label="simple table" size="small">*/}
          {/*    /!* <TableHead>*/}
          {/*  <TableRow>*/}
          {/*    <TableCell>Name</TableCell>*/}
          {/*  </TableRow>*/}
          {/*</TableHead> *!/*/}
          {/*  </Table>*/}
        </CardContent>
      </Card>
    </div>
  );
}
