import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

class Rules extends Component {
  render() {
    return (
      // <div
      // <Container>
      //     <Row>
      //         <Col><h1>Rule Highlights</h1><p>1. 7 innings or 50 minutes ends a game.</p>
      //             <p>2. Mercy rule (10 run difference after 5 innings)</p>
      //             <p>3. Regular season games can end in a tie.</p>
      //             <p>4. Run Differential Cap of 10 runs per game (Ex: 19-2 win is recorded 12-2)</p>
      //             <p>5. Subs can be used (with restrictions &ndash; see rulebook Pg.2 Section 1a.)</p>
      //             <p>6. 3 strikes/fouls is an out. Fouls equal Strikes (Count starts at 0-0 and 3 outs end a half inning)</p>
      //             <p>7. 4 balls is a walk. (4 straight balls without a foul/strike kicker is awarded 2nd base)</p>
      //             <p>8. A minimum of 4 female and a maximum of 6 male players make up the defensive field</p>
      //             <p>9. Home and Away teams are chosen randomly and appears in the schedule (Rock, Paper, Scissors is not used)</p>
      //             <p>10. A pitch has to bounce a minimum of three times before crossing the plate.</p>
      //             <p>There are no restrictions on delivery other than by hand.</p>
      //             <p>Overhand, underhand, spinning or bouncing is allowed in ALL divisions.</p>
      //             <p><strong>KICKING /BUNTING RULES</strong></p>
      //             <p>1. A bunt/kick by a male player has to reach the 1st to 3rd baseline before being considered a fair ball. If</p>
      //             <p>a fielder chooses to field the ball before it reaches this line and while the ball is still in motion the ball is</p>
      //             <p>considered fair. There is no minimum buntline for a female player.</p>
      //             <p>2. There are no restrictions on a kicking line up. No limit on male or females in a row.</p>
      //             <p>3. Any part of a kickers plant foot over the horizontal line of the front of home plate is a foul.</p>
      // //         </Col>
      //     </Row>
      // </Container>
      // </div>
      <div>
        <Container>
          <Row>
            <Col>
              <h1>Rule Highlights</h1>
              <p>1. 7 innings or 50 minutes games with no new inning to start after 45 minutes</p>
              <p>2. Mercy rule (10 run difference after 5 innings)</p>
              <p>Regular season games can end in a tie. Overtime rules are only used in Playoffs. OT starts with the last
                person who was out on 2nd base for the offense and the defense takes 1 male player out of the field.</p>
              <p>4. Run Differential Cap of 10 runs per game (Ex: 19-2 win is recorded 12-2)</p>
              <p>
                5. Subs playing on a current league team can be used but need approved by opposing captain and ref
                informed
              </p>
              <p>
                6. 3 strikes/fouls is an out. Fouls equal Strikes (Count starts at 0-0 and 3 outs end a half inning)
              </p>
              <p>7. 4 balls is a walk. (4 straight balls without a foul/strike kicker is awarded 2nd base)</p>
              <p>8. A minimum of 4 female and a maximum of 6 male players make up the defensive field</p>
              <p>
                9. Home and Away teams are chosen randomly and appears in the schedule (Rock, Paper, Scissors is not
                used)
              </p>
              <p>
                10. Defensive positions can be rotated at anytime except pitcher or catcher that are limited to once per
                inning
              </p>
              <p>
                11. A pitch has to bounce a minimum of <strong>three</strong> times <strong>before</strong> crossing the
                plate.
              </p>
              <p>There are no restrictions on delivery other than by hand.</p>
              <p>Overhand, underhand, spinning or bouncing is allowed in ALL divisions.</p>
              <p>
                <strong>KICKING / BUNTING RULES</strong>
              </p>
              <p>
                1. A bunt/kick by a <strong>male</strong> player has to reach the 1st to 3rd baseline before being
                considered a fair ball. If
              </p>
              <p>
                a fielder chooses to field the ball before it reaches this line and while the ball is still in motion
                the ball is
              </p>
              <p>
                considered fair. There is no minimum buntline for a <strong>female</strong> player.
              </p>
              <p>2. There are no restrictions on a kicking line up. No limit on male or females in a row.</p>
              <p>3. Any part of a kickers plant foot over the horizontal line of the front of home plate is a foul.</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Rules;
