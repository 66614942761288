import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useQuery } from '@apollo/client';
import { GET_TEAM } from '../../queries';
import { Divider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    box: {
      display: 'grid',
      placeItems: 'center'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    container: {
      height: 100,
      align: 'center'
    },
    avatar: {
      backgroundColor: 'white'
    }
  })
);

export default function Roster(props) {
  const classes = useStyles();
  const users = props.users;
  const teamCaptain = props.captain;
  let captain = false;
  const [expanded, setExpanded] = React.useState(true);
  const captainId = teamCaptain.id;
  if (captainId === localStorage.getItem('user-id')) {
    captain = true;
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Card>
        <CardHeader
          title="Roster"
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Table aria-label="simple table" size="small">
              <Typography variant="caption">Player</Typography>
              <TableBody>
                {users?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">
                        {/*{row.id === captainId && (*/}
                        {/*  <img*/}
                        {/*    style={{*/}
                        {/*      color: data.team?.color.hexCode,*/}
                        {/*      marginRight: 10,*/}
                        {/*      padding: 2*/}
                        {/*    }}*/}
                        {/*    src="https://img.icons8.com/ios/25/000000/circled-c.png"*/}
                        {/*  />*/}
                        {/*)}*/}
                        {row.fullName}
                      </Typography>
                      {captain && <div>{row.email}</div>}
                      {/*<span>{row.firstName} </span>*/}
                      {/*<span>{row.lastName}</span>*/}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}
