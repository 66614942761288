import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_EVENT_DETAILS } from '../../queries';
import { Event } from '../../api/models/Event';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    alignItems: 'center'
  }
}));

export default function RefundPolicy(props) {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.paper}>
        <div>
          If you are no longer able to participate and wish to receive a refund for the full season registration fee,
          please contact the league director at{' '}
          <a href="mailto:jason@247kickball.com ?Subject=247Kickball%20Questions" target="_top">
            jason@247kickball.com
          </a>{' '}
          before the close of registration for a full refund.
        </div>
      </Paper>
    </div>
  );
}
